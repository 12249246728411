const selfData = {
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [{
                label: "预警时间",
                prop: "YJSJ"
            }, {
                label: "级别",
                prop: "JB"
            }, {
                label: "类型",
                prop: "LX"
            }, {
                label: "说明",
                prop: "SM"
            }, {
                label: "是否解除",
                prop: "SFJC"
            }],
        },
    },
    snPage: {
        tiaoshu: 20,
        count: 0,
        currentPage: 1,
        operateType: 'page'
    },
    snSearch: {
        dateData: [{
            placeholder: "请选择开始时间",
            value: 'startTime',
            operateType: 'search',
            isShow: true
        }, {
            placeholder: "请选择结束时间",
            value: 'endTime',
            operateType: 'search',
            isShow: true
        }]
    },
    snButton: {
        buttonData: [{
        //     isShow: true,
        //     btnType: 'button',
        //     operateType: 'buttonNew',
        //     name: '新建',
        //     round: true,
        //     backColor: '#28ccd9',
        // }, {
            btnType: 'button',
            operateType: 'buttonExport',
            name: '导出',
            round: true,
            backColor: '#ffcc66',
            color: '#fff'
        }]
    }
};
export { selfData };